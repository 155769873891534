<template>
  <b-modal
    id="meeting-link-modal"
    centered
    hide-footer
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <b-form class="mb-2">
      <h3 class="my-2">Consultation Invitation</h3>

      <b-row>
        <b-col cols="6">
          <validation-provider
            #default="{ errors }"
            name="Date"
            rules="required"
          >
            <b-form-group>
              <span>Date</span>
              <b-form-datepicker
                v-model="date"
                :min="minDate"
              ></b-form-datepicker>
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="6">
          <validation-provider
            #default="{ errors }"
            name="Time"
            rules="required"
          >
            <b-form-group>
              <span>Time</span>
              <b-form-timepicker v-model="time" locale="en"></b-form-timepicker>
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <div class="text-right">
        <b-button
          pill
          size="sm"
          variant="colorGreen"
          @click="submit"
          :disabled="date == null || time == null"
        >
          Send Link
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [util],
  props: {
    consultation: Object,
  },
  data() {
    return {
      required,
      date: null,
      time: null,
    };
  },
  async mounted() {},
  methods: {
    ...mapActions({
      sendMeetingLink: "appData/sendMeetingLink",
    }),

    async submit() {
      try {
        let meetingTime = `${this.date} ${this.time}`;
        const res = await this.sendMeetingLink({
          id: this.consultation.id,
          meeting_time: meetingTime,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Meeting Link sent successfully",
            icon: "success",
          });
          this.date = null;
          this.time = null;
          this.$nextTick(() => {
            this.$bvModal.hide("meeting-link-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
  },

  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    minDate() {
      const today = new Date().toISOString().split("T")[0];
      return today;
    },
  },
};
</script>

<style></style>
